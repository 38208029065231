<template>
  <div class="post-section">
    <div class="cntr cntr-wide">
      <h1>Edycja postu</h1>
      <button class="btn btn-primary" @click="updatePost">Edytuj post</button>
      <div class="message message-errors" v-if="messages.length > 0">
        <ul>
          <li v-for="(message, index) in messages" :key="index">
            {{ message }}
          </li>
        </ul>
      </div>
      <PostForm :post="post" :busy="loading" ref="form"/>
    </div>
  </div>
</template>

<script>
import PostForm from '@/components/PostForm';
import { getOne, update } from "../core/requests/authed-request";

export default {
  name: 'PostDetail',
  props: ['id'],
  components: {
    PostForm
  },
  data() {
    return {
      post: {},
      // editor: null,
      busy: false,
      loading: true,
      messages: []
    }
  },
  methods: {
    getPost() {
      this.loading = true;
      getOne(this.id).then(
        resp => {
          this.post = resp.data;
          const data = typeof this.post.contentData !== 'undefined' && this.post.contentData != '' ? JSON.parse(this.post.contentData) : [];
          this.$refs.form.editorInit(data);
          this.loading = false;
        }
      ).catch(e => {
        this.loading = false;
        console.error(e);
      })
    },
    updatePost() {
      if (!this.busy) {
        this.busy = true;
        this.$refs.form.retriveData().then(
          post => {
            update(this.id, post).then((resp) => {
              if (resp.statusCode == 200) {
                console.log("NOTE: Go to posts view.");
                this.$router.replace({
                  name: 'Posts'
                })
              }
              console.log(resp.statusCode);
              this.busy = false;
            }).catch(error => {
              this.busy = false;
              this.messages = error;
            });
          }
        ).catch(e => {
          this.busy = false;
          console.error(e);
        });
      }
    },
  },
  created() {
  },
  mounted() {
    this.getPost();
  },
  destroyed() {
    // this.editor.destroy();
    // console.log('editorjs destroyed');
  }
}
</script>

<style scoped lang="scss">
</style>